import React, { useState } from "react";
import axios from "axios";

function ForgotOrderId() {
  const [inputs, setInputs] = useState({});
  const [order_id, setOrderID] = useState([]);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const handleClearStatus = () => {
    setOrderID([]); // Clear the order IDs
    setErrors({}); // Clear any previous validation errors
    setInputs({}); // Clear the input fields
  };

  const validateForm = () => {
    const errors = {};
    if (!inputs.mobile_number) {
      errors.mobile_number = "Mobile number is required";
    }
    if (!inputs.email) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(inputs.email)) {
      errors.email = "Invalid email format";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // If there are no errors, the form is valid
  };

  const getOrderID = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    if (validateForm()) {
      const { mobile_number, email } = inputs;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE}/forgot_order_id/`,
          {
            params: {
              mobile_number,
              email,
            },
          }
        );
        setOrderID(response.data);
        if (order_id.length > 0) {
          const formattedOrderIDs = order_id
            .map((order) => order.order_id)
            .join(", ");
          window.alert("Your Order ID's are - " + formattedOrderIDs);
        } else {
          window.alert("No matching order found.");
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className="track-order-container">
      <section className="track-order-area ptb-100">
        <div className="container">
          <div className="track-order-content">
            <h2>Find Your Order ID</h2>

            <form onSubmit={getOrderID}>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile_number"
                  onChange={handleChange}
                />
                {errors.mobile_number && (
                  <p className="error-message">{errors.mobile_number}</p>
                )}
              </div>

              <div className="form-group">
                <label>Email ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="error-message">{errors.email}</p>
                )}
              </div>

              <button type="submit" className="default-btn">
                Find Order ID
              </button>

              <button
                onClick={handleClearStatus}
                style={{ marginLeft: "10px" }}
                className="default-btn"
              >
                Clear
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForgotOrderId;
