import React, { createContext, useState, useContext } from "react";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cart, setCart] = useState([]);

  const addToCart = (productDetails, Quantity) => {
    // Add the product to the cart
    setCart([...cart, { productDetails, Quantity }]);
  };

  const clearCart = (product_id) => {
    const updatedCart = cart.filter(
      (item) => item.productDetails.product_id !== product_id
    );
    setCart(updatedCart);
  };

  const clearAllCart = () => {
    setCart([]); // Clear the cart by setting it to an empty array
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, clearCart, clearAllCart }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
