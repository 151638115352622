import React, { useState, useLayoutEffect } from "react";
import axios from "axios";

function Contact() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [formData, setFormData] = useState({
    name: "",
    mobile_number: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE}/enquiry/`, formData);
      window.alert("Thanks! We will Contact You Soon");
      // Reset form data
      setFormData({
        name: "",
        mobile_number: "",
        email: "",
        message: "",
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div>
        <section className="contact-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="contact-info">
                  <h3>Here to Help</h3>
                  <p>
                    Have a question? You may find an answer in our{" "}
                    <a href="Contact-Us">FAQs</a>. But you can also contact us.
                  </p>
                  <ul className="contact-list">
                    <li>
                      <i className="bx bx-map" /> Location:{" "}
                      <a href="Contact-Us">
                        The Plantarium Nursery, 520/2E, Sathy Main Road, Near By
                        CRI Pumps, Kurumbapalayam, Coimbatore - 641 107
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-phone-call" /> Call Us:{" "}
                      <a href="tel:+919943734632">+91 99437 34632</a>
                    </li>
                    <li>
                      <i className="bx bx-phone-call" /> Call Us:{" "}
                      <a href="tel:+91 86087 56439">+91 86087 56439</a>
                    </li>
                    <li>
                      <i className="bx bx-envelope" /> Email Us:{" "}
                      <a href="mailto:plantariumgardencenter@gmail.com">
                        plantariumgardencenter@gmail.com
                      </a>
                    </li>
                  </ul>
                  <h3>Opening Hours:</h3>
                  <ul className="opening-hours">
                    <li>
                      <span>Monday:</span> 8AM - 6AM
                    </li>
                    <li>
                      <span>Tuesday:</span> 8AM - 6AM
                    </li>
                    <li>
                      <span>Wednesday:</span> 8AM - 6AM
                    </li>
                    <li>
                      <span>Thursday:</span> 8AM - 6AM
                    </li>
                    <li>
                      <span>Friday:</span> 8AM - 6AM
                    </li>
                    <li>
                      <span>Saturday:</span> 8AM - 6AM
                    </li>
                    <li>
                      <span>Sunday:</span> Closed
                    </li>
                  </ul>
                  <h3>Follow Us:</h3>
                  <ul className="social">
                    <li>
                      <a href="Home">
                        <i className="bx bxl-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="Home">
                        <i className="bx bxl-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="contact-form">
                  <h3>Drop Us A Line</h3>
                  <p>
                    We're happy to answer any questions you have or provide you
                    with an estimate. Just send us a message in the form below
                    with any questions you may have.
                  </p>
                  <form id="contactForm" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Name <span>*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            required
                            data-error="Please enter your name"
                            placeholder="Your name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Email <span>*</span>
                          </label>
                          <input
                            type="email"
                            id="email"
                            className="form-control"
                            required
                            data-error="Please enter your email"
                            placeholder="Your email address"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Phone Number <span>*</span>
                          </label>
                          <input
                            type="text"
                            id="phone_number"
                            className="form-control"
                            required
                            data-error="Please enter your phone number"
                            placeholder="Your phone number"
                            name="mobile_number"
                            value={formData.mobile_number}
                            onChange={handleChange}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Your Message <span>*</span>
                          </label>
                          <textarea
                            id="message"
                            cols={30}
                            rows={5}
                            required
                            data-error="Please enter your message"
                            className="form-control"
                            placeholder="Write your message..."
                            defaultValue={""}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button type="submit" className="default-btn">
                          Send Message
                        </button>
                        <div id="msgSubmit" className="h3 text-center hidden" />
                        <div className="clearfix" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div id="map">
          <iframe
            title="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.112568665287!2d77.02074461002864!3d11.104987553002966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f91da6b9fa99%3A0xdfd706aee70ee6c2!2sThe%20Plantarium%20Nursery!5e0!3m2!1sen!2sin!4v1697731756992!5m2!1sen!2sin"
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
