import React, { useState, useEffect } from "react";
import axios from "axios";

function Footer() {

  const [about_us, setabout_us] = useState([]);

  useEffect(() => {
    getabout_us();
  }, []);

  const getabout_us = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/aboutus/`)
        .then(function (response) {
          setabout_us(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  }

  let about_us_html__data = about_us.map((about_us) => (
    <p>{about_us.content_1}</p>
  ));


  return (
    <div>
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>About My Plantarium</h3>
                <div className="about-the-store">
                  {about_us_html__data}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Contact Us</h3>
                <div className="about-the-store">
                  <ul className="footer-contact-info">
                    <li>
                      <i className="bx bx-map" />{" "}
                      <a href="https://maps.app.goo.gl/jWBTkV6q9AXdRsGSA">
                        The Plantarium Nursery, 520/2E, Sathy Main Road, Near By
                        CRI Pumps, Kurumbapalayam, Coimbatore - 641 107
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-phone-call" />{" "}
                      <a href="tel:+919943734632">+91 99437 34632</a>
                    </li>
                    <li>
                      <i className="bx bx-phone-call" />{" "}
                      <a href="tel:++918608756439">+91 86087 56439</a>
                    </li>
                    <li>
                      <i className="bx bx-envelope" />{" "}
                      <a href="mailto:plantariumgardencenter@gmail.com">
                        plantariumgardencenter@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget pl-4">
                <h3>Quick Links</h3>
                <ul className="quick-links">
                  <li className="nav-item">
                    <a href="Home" className="nav-link active">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="About-Us" className="nav-link">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="Products" className="nav-link">
                      Products
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="TrackOrder" className="nav-link">
                      Track Order
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="Cart" className="nav-link">
                      Cart
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="Contact-Us" className="nav-link">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h3>Follow Us</h3>
                <ul className="social-link">
                  <li>
                    <a href="Home" className="d-block" target="_blank">
                      <i className="bx bxl-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="Home" className="d-block" target="_blank">
                      <i className="bx bxl-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-bottom-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p>
                  My Plantarium is Designed By{" "}
                  <a href="https://dailydaysolutions.com/">DailyDaysolutions</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lines">
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
      </footer>
    </div>
  );
}

export default Footer;
