import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Products from "./components/Products";
import Contact from "./components/Contact";
import ProductDetails from "./components/ProductDetails";
import AboutUs from "./components/AboutUs";
import CheckOut from "./components/CheckOut";
import TrackOrder from "./components/TrackOrder";
import { CartProvider } from "./components/CartContext";
import Cart from "./components/Cart";
import ForgotOrderId from "./components/ForgotOrderId";
import SearchProducts from "./components/SearchProducts";

function App() {
  return (
    <div className="App">
      <CartProvider>
        <Router>
          <Header></Header>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Home" element={<Home />} />
            <Route exact path="/About-Us" element={<AboutUs />} />
            <Route exact path="/Products" element={<Products />} />
            <Route exact path="/ProductDetails" element={<ProductDetails />} />
            <Route exact path="/Contact-Us" element={<Contact />} />
            <Route exact path="/Checkout" element={<CheckOut />} />
            <Route exact path="/TrackOrder" element={<TrackOrder />} />
            <Route exact path="/Cart" element={<Cart />} />
            <Route exact path="/ForgotOrderId" element={<ForgotOrderId />} />
            <Route exact path="/SearchProducts" element={<SearchProducts />} />
          </Routes>
          <Footer></Footer>
        </Router>
      </CartProvider>
    </div>
  );
}

export default App;
