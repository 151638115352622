import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function TrackOrder() {
  const [orderID, setOrderID] = useState("");
  const [orderStatus, setOrderStatus] = useState(""); // Initialize with an empty status
  const statuses = ["Placed", "Cancelled", "Confirmed", "Shipped", "Delivered"];
  const [track_order, settrack_order] = useState([]);

  const gettrack_order = async (orderId) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/track_order/${orderId}`)
        .then(function (response) {
          settrack_order(response.data);
          setOrderStatus(track_order.order_status);
          if (!response.data) {
            window.alert("No Order ID Found. Please Contact Our Support Team");
            window.location.reload();
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleTrackOrder = (e) => {
    e.preventDefault();
    gettrack_order(orderID);
  };

  const handleClearStatus = () => {
    setOrderStatus("");
  };

  return (
    <div className="track-order-container">
      <section className="track-order-area ptb-100">
        <div className="container">
          <div className="track-order-content">
            <h2>Track Your Order</h2>

            <form onSubmit={handleTrackOrder}>
              <div className="form-group">
                <label>Order ID</label>
                <input
                  type="number"
                  className="form-control"
                  value={orderID}
                  onChange={(e) => setOrderID(e.target.value)}
                />
              </div>

              <button type="submit" className="default-btn">
                Track Order
              </button>

              <button
                onClick={handleClearStatus}
                style={{ marginLeft: "10px" }}
                className="default-btn"
              >
                Clear
              </button>

              <Link
                to="/ForgotOrderId"
                style={{ marginLeft: "40px" }}
                className="default-btn"
              >
                Forgot Order Id ?
              </Link>
            </form>

            <div className="timeline">
              {statuses.map((status, index) => (
                <div
                  key={status}
                  className={`timeline-item ${
                    status === orderStatus ? "active" : ""
                  }`}
                >
                  <div className="timeline-circle">
                    {status === orderStatus && (
                      <div className="current-circle" />
                    )}
                  </div>
                  <p>{status}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TrackOrder;
