import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";

function AboutUs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [about_us, setabout_us] = useState([]);

  useEffect(() => {
    getabout_us();
  }, []);

  const getabout_us = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/aboutus/`)
        .then(function (response) {
          setabout_us(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let about_us_html_data = about_us.map((about_us) => (
    <div className="about-content">
      <Helmet>
        <script src="assets/js/main.js" type="text/javascript" />
      </Helmet>
      <span className="sub-title">About Us</span>
      <h2>{about_us.heading_1}</h2>
      <p>
        <strong>{about_us.content_1}</strong>
      </p>
      <div className="features-text">
        <h5>
          <i className="bx bx-planet" />
          {about_us.heading_2}
        </h5>
        <p>{about_us.content_2}</p>
      </div>
    </div>
  ));

  const [our_partners, setour_partners] = useState([]);

  useEffect(() => {
    getour_partners();
  }, []);

  const getour_partners = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/our_partners/`)
        .then(function (response) {
          setour_partners(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let our_partners_html_data = our_partners.map((our_partners) => (
    <div className="partner-item">
      <a href="Home">
        <Image
          src={`${our_partners.image_path.substring(1, 1000)}`}
          alt="About-us"
        />
      </a>
    </div>
  ));

  const [testimonial, settestimonial] = useState([]);

  useEffect(() => {
    gettestimonial();
  }, []);

  const gettestimonial = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/testimonial/`)
        .then(function (response) {
          settestimonial(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let testimonial_html_data = testimonial.map((testimonial) => (
    <div className="single-testimonials-item">
      <p>{testimonial.message}</p>
      <div className="info">
        <Image
          src={`${testimonial.image_path.substring(1, 1000)}`}
          className="shadow rounded-circle"
          alt="About-us"
        />
        <h3>{testimonial.client_name}</h3>
        <span>{testimonial.business_name}</span>
      </div>
    </div>
  ));

  return (
    <div>
      <div>
        <section className="about-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-image">
                  <img
                    src="assets/img/about/img1.jpg"
                    className="shadow"
                    alt="about-us"
                  />
                  <img
                    src="assets/img/about/img2.jpg"
                    className="shadow"
                    alt="about-us"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">{about_us_html_data}</div>
            </div>
            <div className="about-inner-area">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="about-text">
                    <h3>Our Story</h3>
                    <ul className="features-list">
                      <li>
                        <i className="bx bx-check" />
                        We are a team of professional graduates of Horticulture
                        more passionate about plants and their habitat.
                      </li>
                      <li>
                        <i className="bx bx-check" />
                        We would like to transfer our expertise/knowledge on how
                        to grow and care plants to all the plant lovers through
                        this platform.
                      </li>
                      <li>
                        <i className="bx bx-check" />
                        We are choosing the prime quality of planting materials
                        for propagation and applied knowledge on growing to
                        deliver the best quality ever.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="about-text">
                    <h3>Our Mission</h3>
                    <ul className="features-list">
                      <li>
                        <i className="bx bx-check" />
                        Aiming to deliver the Premium range of Plants and
                        Gardening materials which make your place more aesthetic
                        and lively.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                  <div className="about-text">
                    <h3>Our Vision</h3>
                    <ul className="features-list">
                      <li>
                        <i className="bx bx-check" />
                        Engage and entertain the experience of the urban
                        community to grow more greenery in a sustainable way
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="partner-area ptb-70">
          <div className="container">
            <div className="section-title">
              <h2>Our Partners</h2>
            </div>
            <div className="partner-slides owl-carousel owl-theme">
              {our_partners_html_data}
            </div>
          </div>
        </div>

        <section className="testimonials-area ptb-100">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Testimonials</span>
              <h2>What Clients Says About Us</h2>
            </div>
            <div className="testimonials-slides owl-carousel owl-theme">
              {testimonial_html_data}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutUs;
