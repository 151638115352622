import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";

function SearchProducts(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const location = useLocation();
  const { searchValue } = location.state || {};

  const [products, setproducts] = useState([]);

  useEffect(() => {
    getproducts();
    // eslint-disable-next-line
  }, []);

  const getproducts = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/search_products/${searchValue}`)
        .then(function (response) {
          setproducts(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  /*     const [category, setcategory] = useState([]);
    
    useEffect(() => {
        getcategory();
    }, []);
    
    const getcategory = async () => {
        try {
        await axios
            .get(`${process.env.REACT_APP_API_BASE}/category`)
            .then(function (response) {
            setcategory(response.data);
            });
        } catch (err) {
        console.error(err);
        }
      };

    let category_html_data = category.map((category) => (
        <option>{category.category_name}</option>
    )); */

  let data = products;

  let products_html_data = data.map((data) => (
    <div className="col-lg-4 col-md-6 col-sm-6 products-col-item">
      <Helmet>
        <script src="assets/js/main.js" type="text/javascript" />
      </Helmet>
      <div className="single-products-box">
        <div className="products-image">
          <Link to={`/ProductDetails?product_id=${data.product_id}`}>
            <Image
              src={`${
                data.image_1_path ? data.image_1_path.substring(1, 1000) : ""
              }`}
              className="main-image"
              alt="Product"
            />
            <Image
              src={`${
                data.image_2_path ? data.image_2_path.substring(1, 1000) : ""
              }`}
              className="hover-image"
              alt="Product"
            />
          </Link>
        </div>
        <div className="products-content">
          <h3>
            <a href="Home">{data.name}</a>
          </h3>
          <div className="price">
            <span className="new-price">RS.{data.price}</span>
          </div>
          <Link
            to={`/ProductDetails?product_id=${data.product_id}`}
            className="add-to-cart"
          >
            Add to Cart
          </Link>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <div>
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>Products</h2>
              <ul>
                <li>
                  <a href="Home">Home</a>
                </li>
                <li>Products</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="products-area pt-100 pb-70">
          <div className="container">
            <div className="products-filter-options">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-4">
                  <div className="d-lg-flex d-md-flex align-items-center">
                    <span className="sub-title d-none d-lg-block d-md-block">
                      View:
                    </span>
                    {/* eslint-disable */}
                    <div className="view-list-row d-none d-lg-block d-md-block">
                      <div className="view-column">
                        <a href="#" className="icon-view-two">
                          <span />
                          <span />
                        </a>
                        <a href="#" className="icon-view-three active">
                          <span />
                          <span />
                          <span />
                        </a>
                        <a href="#" className="icon-view-four">
                          <span />
                          <span />
                          <span />
                          <span />
                        </a>
                        <a href="#" className="view-grid-switch">
                          <span />
                          <span />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {products.length > 0 ? (
              <div id="products-collections-filter" className="row">
                {products_html_data}
              </div>
            ) : (
              <div>No products found</div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default SearchProducts;
