import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useCart } from "./CartContext";
import { Image } from "react-bootstrap";
import { Carousel } from "react-bootstrap";

function ProductDetails() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    script.defer = true; // Add the 'defer' attribute
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const { addToCart } = useCart();

  const handleAddToCart = (products, Quantity) => {
    addToCart(products, Quantity);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("product_id");

  const [counter, setCounter] = useState(1);
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };

  const [activeTab, setActiveTab] = useState("Description");
  const [showDescription, setShowDescription] = useState(true);
  const [showReviews, setShowReviews] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "Description") {
      setShowDescription(true);
      setShowReviews(false);
    } else if (tabName === "Reviews") {
      setShowDescription(false);
      setShowReviews(true);
    }
  };

  const [products, setproducts] = useState([]);

  useEffect(() => {
    getproducts();
    // eslint-disable-next-line
  }, []);

  const getproducts = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/products/${productId}/`)
        .then(function (response) {
          setproducts(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const imageSources = products
    ? [
        products.image_1_path ? products.image_1_path.substring(1, 1000) : "",
        products.image_2_path ? products.image_2_path.substring(1, 1000) : "",
        products.image_3_path ? products.image_3_path.substring(1, 1000) : "",
        products.image_4_path ? products.image_4_path.substring(1, 1000) : "",
      ]
    : [];

  const [reviews, setreviews] = useState([]);

  useEffect(() => {
    getreviews();
    // eslint-disable-next-line
  }, []);

  const getreviews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/reviews_approved/${productId}/`
      );
      setreviews(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  let review_html_data = reviews.map((review, key) => {
    const createdDate = new Date(review.created_at);
    const formattedDate = createdDate.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    return (
      <div className="review-item" key={key}>
        <h3>{review.tittle}</h3>
        <span>
          <strong>{review.user_name}</strong> on -{" "}
          <strong>{formattedDate}</strong>
        </span>
        <p>{review.comments}</p>
      </div>
    );
  });

  const [sugessted_products, setsugessted_products] = useState([]);

  useEffect(() => {
    getsugessted_products();
    // eslint-disable-next-line
  }, []);

  const getsugessted_products = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/suggested_products_details/${productId}/`
      );
      setsugessted_products(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  let suggested_products_html_data = sugessted_products.map(
    (sugessted_products, key) => (
      <div className="col-lg-4 col-md-6 col-sm-6 products-col-item">
        <div className="single-products-box" key={key}>
          <div className="products-image">
            <Link
              to={`/ProductDetails?product_id=${sugessted_products.suggested_product}`}
            >
              <Image
                src={`${sugessted_products.image_path.substring(1, 1000)}`}
                className="main-image"
                alt="Product"
              />
              <Image
                src={`${sugessted_products.image_path.substring(1, 1000)}`}
                className="hover-image"
                alt="Product"
              />
            </Link>
          </div>
          <div className="products-content">
            <h3>
              <Link
                to={`/ProductDetails?product_id=${sugessted_products.suggested_product}`}
              >
                {sugessted_products.name}
              </Link>
            </h3>
            <div className="price">
              <span className="new-price">{sugessted_products.price}</span>
            </div>
          </div>
        </div>
      </div>
    )
  );

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      inputs.product_id = productId;
      await axios.post(`${process.env.REACT_APP_API_BASE}/reviews/`, inputs);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div>
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>{products.name}</h2>
              <ul>
                <li>
                  <a href="Home">Home</a>
                </li>
                <li>Products Details</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="product-details-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="products-details-image">
                  <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
                    {imageSources.map((src, index) => (
                      <Carousel.Item key={index}>
                        <img src={src} alt={`Product ${index}`} />
                      </Carousel.Item>
                    ))}
                  </Carousel>

                  <div className="slick-thumbs" style={{ display: "flex" }}>
                    {imageSources.map((src, index) => (
                      <div
                        key={index}
                        style={{ width: "100px" }}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img src={src} alt={`Thumbnail ${index}`} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="products-details-desc">
                  <h3>{products.name}</h3>
                  <div className="price">
                    <span className="new-price">RS. {products.price}</span>
                  </div>
                  <p>{products.description}</p>
                  <div className="products-add-to-cart">
                    <div className="input-counter">
                      <span className="minus-btn" onClick={handleDecrement}>
                        <i className="bx bx-minus" />
                      </span>
                      <input type="text" value={counter} readOnly />
                      <span className="plus-btn" onClick={handleIncrement}>
                        <i className="bx bx-plus" />
                      </span>
                    </div>
                    <button
                      onClick={() => handleAddToCart(products, counter)}
                      type="submit"
                      className="default-btn"
                    >
                      <i className="fas fa-cart-plus" /> Add to Cart
                    </button>
                    {/* <Link to={`/Checkout?product_id=${products.product_id}&name=${products.name}&price=${products.price}&quantity=${counter}`} className="default-btn">Buy it now!</Link> */}
                  </div>

                  {/* eslint-disable */}
                  <div className="buy-checkbox-btn">
                    <div className="item">
                      <Link
                        to={`/Checkout?product_id=${products.product_id}&name=${products.name}&price=${products.price}&quantity=${counter}`}
                        className="default-btn"
                      >
                        Buy it now!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab products-details-tab">
              <ul className="tabs">
                <li className={activeTab === "Description" ? "current" : ""}>
                  <a href="#" onClick={() => handleTabClick("Description")}>
                    <div className="dot" /> Description
                  </a>
                </li>
                <li className={activeTab === "Reviews" ? "current" : ""}>
                  <a href="#" onClick={() => handleTabClick("Reviews")}>
                    <div className="dot" /> Reviews
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tabs-item"
                  style={{ display: showDescription ? "block" : "none" }}
                >
                  <div className="products-details-tab-content">
                    <p>{products.description}</p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <ul>
                          <li>{products.feature_1}</li>
                          <li>{products.feature_2}</li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <ol>
                          <li>{products.feature_3}</li>
                          <li>{products.feature_4}</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tabs-item"
                  style={{ display: showReviews ? "block" : "none" }}
                >
                  <div className="products-details-tab-content">
                    <div className="products-review-form">
                      <h3>Customer Reviews</h3>
                      <div className="review-title">
                        <a href="#" className="default-btn">
                          Write a Review
                        </a>
                      </div>
                      <div className="review-comments">{review_html_data}</div>
                      <div className="review-form">
                        <h3>Write a Review</h3>
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="name"
                                  name="user_name"
                                  placeholder="Enter your name"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="Enter your email"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="mobile"
                                  id="mobile"
                                  name="mobile_number"
                                  placeholder="Enter your Mobile Number"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="review-title"
                                  name="tittle"
                                  placeholder="Enter your Review Title"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <input
                                  type="number"
                                  id="review-title"
                                  name="rating"
                                  placeholder="Enter your Rating out of 5"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <textarea
                                  name="comments"
                                  id="review-body"
                                  cols={30}
                                  rows={6}
                                  placeholder="Write your comments here"
                                  className="form-control"
                                  defaultValue={""}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <button type="submit" className="default-btn">
                                Submit Review
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="related-products">
            <div className="container">
              <div className="section-title">
                <h2>Some Related Products</h2>
              </div>
              <div className="row">{suggested_products_html_data}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ProductDetails;
