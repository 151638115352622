import React from "react";
import axios from "axios";
import { useEffect, useState, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useCart } from "./CartContext";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function Home() {
  const { addToCart } = useCart();

  const handleDragStart = (e) => e.preventDefault();

  const handleAddToCart = (products, Quantity) => {
    addToCart(products, Quantity); // Add one item of the product to the cart
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [offers, setoffers] = useState([]);

  useEffect(() => {
    getoffers();
  }, []);

  const getoffers = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/offers/`)
        .then(function (response) {
          setoffers(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let offers_html_data = offers.map((offers) => (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <Helmet>
        <script src="assets/js/main.js" type="text/javascript" />
      </Helmet>
      <div className="single-categories-box">
        <Image src={`${offers.image_path.substring(1, 1000)}`} alt="text" />
        <div className="content">
          {/*             <span>{offers.primary_tittle}</span>
                <h3>{offers.secondary_tittle}</h3> */}
          {/* eslint-disable */}
          <a href="Products" className="default-btn">
            Buy Now !
          </a>
        </div>
        <a href="Products" className="link-btn"></a>
      </div>
    </div>
  ));

  const [top_products, settop_products] = useState([]);

  useEffect(() => {
    gettop_products();
  }, []);

  const gettop_products = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/top_products/`)
        .then(function (response) {
          settop_products(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let top_products_html_data = top_products.map((top_products) => (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-products-box">
        <div className="products-image">
          <Link to={`/ProductDetails?product_id=${top_products.product_id}`}>
            <Image
              src={`${
                top_products.image_1_path
                  ? top_products.image_1_path.substring(1, 1000)
                  : ""
              }`}
              className="main-image"
              alt="text"
            />
            <Image
              src={`${
                top_products.image_2_path
                  ? top_products.image_2_path.substring(1, 1000)
                  : ""
              }`}
              className="hover-image"
              alt="text"
            />
          </Link>
        </div>
        <div className="products-content">
          <h3>
            <Link to={`/ProductDetails?product_id=${top_products.product_id}`}>
              {top_products.name}
            </Link>
          </h3>
          <div className="price">
            <span className="new-price">RS.{top_products.price}</span>
          </div>
          <a
            onClick={() => handleAddToCart(top_products, 1)}
            className="add-to-cart"
          >
            Add to Cart
          </a>
        </div>
      </div>
    </div>
  ));

  const [banner, setbanner] = useState([]);

  useEffect(() => {
    getbanner();
  }, []);

  const getbanner = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/banner/`)
        .then(function (response) {
          setbanner(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let banner_html_data = banner.map((banner, key) => (
    <img
      key={key}
      src={`${banner.image_path.substring(1, 1000)}`}
      onDragStart={handleDragStart}
      role="presentation"
      alt=""
    />
  ));

  return (
    <div>
      <div>
        <AliceCarousel
          mouseTracking
          items={banner_html_data}
          disableDotsControls={true}
          disableButtonsControls={true}
          autoPlay={true}
          infinite={true}
          animationDuration={1000}
          autoPlayInterval={2000}
        />

        <section className="categories-banner-area pt-100 pb-70">
          <div className="container-fluid">
            <div className="section-title">
              <h2>Our Best Offers</h2>
            </div>
            <div className="row">{offers_html_data}</div>
          </div>
        </section>

        <section className="products-area pb-70">
          <div className="container">
            <div className="section-title">
              <h2>Our Top Products</h2>
            </div>
            <div className="row">{top_products_html_data}</div>
          </div>
        </section>

        <section className="facility-area pb-70">
          <div className="container">
            <div className="facility-slides owl-carousel owl-theme">
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-return" />
                </div>
                <h3>Easy Return Policy</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-sale" />
                </div>
                <h3>Weekend Discount Coupon</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-credit-card" />
                </div>
                <h3>Secure Payment Methods</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-location" />
                </div>
                <h3>Track Your Package</h3>
              </div>
              <div className="single-facility-box">
                <div className="icon">
                  <i className="flaticon-customer-service" />
                </div>
                <h3>24/7 Customer Support</h3>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
