import React, { useLayoutEffect } from "react";
import { useCart } from "./CartContext";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function Cart() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const { cart, clearCart, clearAllCart } = useCart();

  const handleClearCart = (product_id) => {
    clearCart(product_id);
  };

  const handleClearAllCart = () => {
    clearAllCart();
  };

  let subtotal = cart.reduce((total, cartItem) => {
    return total + cartItem.productDetails.price * cartItem.Quantity;
  }, 0);

  let productsQuery = "";

  productsQuery = cart
    .map((cart) => {
      return `product_id=${cart.productDetails.product_id}&name=${cart.productDetails.name}&quantity=${cart.Quantity}&price=${cart.productDetails.price}`;
    })
    .join("&");

  let cart_html_data = cart.map((cart) => (
    <tr>
      <td className="product-thumbnail">
        <a href="Home">
          <Image
            src={`${cart.productDetails.image_1_path.substring(1, 1000)}`}
            alt="Awesome_Image"
            style={{ width: "150px" }}
          />
        </a>
      </td>
      <td className="product-name">
        <a href="Home">{cart.productDetails.name}</a>
      </td>
      <td className="product-price">
        <span className="unit-amount">{cart.productDetails.price}</span>
      </td>
      <td className="product-quantity">
        <div className="input-counter">
          <input type="text" min={1} defaultValue={cart.Quantity} />
        </div>
      </td>
      <td className="product-subtotal">
        {/* eslint-disable */}
        <span className="subtotal-amount">
          RS.{cart.productDetails.price * cart.Quantity}
        </span>
        <a
          onClick={() => handleClearCart(cart.productDetails.product_id)}
          className="remove"
        >
          <i className="bx bx-trash" />
        </a>
      </td>
    </tr>
  ));

  const isCartEmpty = cart.length === 0;
  return (
    <div>
      <div>
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>Cart</h2>
              <ul>
                <li>
                  <a href="Home">Home</a>
                </li>
                <li>Cart</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="cart-area ptb-100">
          <div className="container">
            {isCartEmpty ? (
              <div className="empty-cart-message">
                <p>Your cart is empty.</p>
              </div>
            ) : (
              <form>
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Name</th>
                        <th scope="col">Unit Price</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>{cart_html_data}</tbody>
                  </table>
                </div>
                <div className="cart-buttons">
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-sm-7 col-md-7">
                      <a href="Products" className="optional-btn">
                        Continue Shopping
                      </a>
                    </div>
                    <div className="col-lg-5 col-sm-5 col-md-5 text-end">
                      <a
                        onClick={() => handleClearAllCart()}
                        className="default-btn"
                      >
                        Clear Cart
                      </a>
                    </div>
                  </div>
                </div>
                <div className="cart-totals">
                  <h3>Cart Totals</h3>
                  <ul>
                    <li>
                      Total <span>RS.{subtotal}</span>
                    </li>
                  </ul>
                  <Link
                    to={`/Checkout?${productsQuery}`}
                    className="default-btn"
                  >
                    Proceed to Checkout
                  </Link>
                </div>
              </form>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Cart;
