import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

function CheckOut() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsArray = [];
    queryParams.forEach((value, key) => {
      if (key === "product_id") {
        productsArray.push({
          id: value,
          name: queryParams.get("name"),
          price: queryParams.get("price"),
          quantity: queryParams.get("quantity"),
        });
      }
    });
    setProducts(productsArray);
    // eslint-disable-next-line
  }, [location.search]);

  const [inputs, setInputs] = useState({});
  const [inputs1, setInputs1] = useState({});
  const [inputs2, setInputs2] = useState({});
  const [paymentMethod, setpaymentMethod] = useState({});

  const handleUserInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleUserAddressChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs1((values) => ({ ...values, [name]: value }));
  };

  const handleOrderChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setpaymentMethod(event.target.value);
    setInputs2((values) => ({ ...values, [name]: value }));
  };

  const handlePaymentMethodChange = (event) => {
    setpaymentMethod(event.target.value);
  };

  const handlePlaceOrder = async () => {
    try {
      // Create a new user
      const userResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE}/user/`,
        inputs
      );
      const userId = userResponse.data.user_id;

      if (userId) {
        // Calculate the total price for the entire order
        let totalPrice = 0;
        products.forEach((product) => {
          totalPrice += product.price * product.quantity;
        });

        // Get the current date for order creation
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const orderDate = `${day}/${month}/${year}`;

        // Create the order
        const orderData = {
          user_id: userId,
          order_date: orderDate,
          order_status: "Placed",
          total_price: totalPrice,
          notes: inputs2.notes,
          payment_method: paymentMethod,
        };

        const orderResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE}/order/`,
          orderData
        );
        const orderId = orderResponse.data.order_id;

        if (orderId) {
          // Loop through each product and create order items
          for (const product of products) {
            const orderItemData = {
              order_id: orderId,
              product_id: product.id,
              quantity: product.quantity,
              total: product.quantity * product.price,
            };

            // Create an order item
            const orderItem = await axios.post(
              `${process.env.REACT_APP_API_BASE}/order_item/`,
              orderItemData
            );

            if (orderItem.data.status === 1) {
              // Update the product information (e.g., reduce the available quantity)
              await axios.put(
                `${process.env.REACT_APP_API_BASE}/products/${product.id}`,
                {
                  // You might need to adjust the update data structure as per your API
                  quantity: product.quantity,
                  product_id: product.id,
                }
              );
            }
          }

          // Create shipping information
          const shippingData = {
            order_id: orderId,
            address: inputs1.address,
            city: inputs1.city,
            state: inputs1.state,
            zip_code: inputs1.zip_code,
          };
          await axios.post(
            `${process.env.REACT_APP_API_BASE}/shipping/`,
            shippingData
          );

          // Display the order ID to the user
          window.alert(
            `Thank You! Your Order is Placed This is Your Order ID - ${orderId}`
          );
          window.location.reload();
        } else {
          window.alert("Can't place your order now. Please try again later.");
        }
      } else {
        window.alert("Can't place your order now. Please try again later.");
      }
    } catch (error) {
      window.alert("Can't place your order now. Please try again later.");
    }
  };

  let checkout_html_data = products.map((products, key) => (
    <tr key={key}>
      <td className="product-name">
        {/* eslint-disable */}
        <a href="#">{products.name}</a>
      </td>
      <td className="product-name">
        {/* eslint-disable */}
        <a href="#">{products.quantity}</a>
      </td>
      <td className="product-total">
        <span className="subtotal-amount">
          RS.{products.price * products.quantity}
        </span>
      </td>
    </tr>
  ));

  return (
    <div>
      {/* eslint-disable */}
      <div>
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>Checkout</h2>
              <ul>
                <li>
                  <a href="Home">Home</a>
                </li>
                <li>Checkout</li>
              </ul>
            </div>
          </div>
        </div>

        <section className="checkout-area ptb-100">
          <div className="container">
            <form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="billing-details">
                    <h3 className="title">
                      Shipping Details (* Fields Are Mandatory)
                    </h3>
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            onChange={handleUserInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Address <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="address"
                            className="form-control"
                            onChange={handleUserAddressChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Town / City <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            onChange={handleUserAddressChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            State <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="state"
                            className="form-control"
                            onChange={handleUserAddressChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Postcode / Zip <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="zip_code"
                            className="form-control"
                            onChange={handleUserAddressChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Email Address <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            onChange={handleUserInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Phone <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="mobile_number"
                            className="form-control"
                            onChange={handleUserInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="notes"
                            id="notes"
                            cols={30}
                            rows={5}
                            placeholder="Order Notes"
                            className="form-control"
                            defaultValue={""}
                            onChange={handleOrderChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="order-details">
                    <h3 className="title">Your Order</h3>
                    <div className="order-table table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Product Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                          </tr>
                        </thead>
                        <tbody>{checkout_html_data}</tbody>
                      </table>
                    </div>
                    <div className="payment-box">
                      <div className="payment-method">
                        {/*                       <p>
                        <input type="radio" id="online-payment" name="radio-group" value="Online Payment" onChange={handlePaymentMethodChange}/>
                        <label htmlFor="online-payment">Online Payment</label>
                      </p> */}
                        <p>
                          <input
                            type="radio"
                            id="cash-on-delivery"
                            name="radio-group"
                            value="Cash On Delivery"
                            onChange={handlePaymentMethodChange}
                          />
                          <label htmlFor="cash-on-delivery">
                            Cash on Delivery
                          </label>
                        </p>
                      </div>
                      <a onClick={handlePlaceOrder} className="default-btn">
                        Place Order
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CheckOut;
